package com.steamstreet.vegasful.browser.public

import kotlinx.browser.window
import kotlinx.dom.addClass
import org.w3c.dom.Element

/**
 * Simple module that displays the back button when displayed in ios 'web application'
 * mode.
 */
fun iosWebAppBack(element: Element) {
    val isStandalone = window.navigator.asDynamic().standalone
    if (isStandalone == true) {
        element.addClass("ios-web-application")

        element.addEventListener("click", {
            window.history.back()
        })
    }
}

package com.steamstreet.vegasful.browser.public

import kotlinx.browser.document
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.url.URL

/**
 * Initialize the global search
 */
fun globalSearch(element: Element) {
    val input = (element.querySelector("input") as? HTMLInputElement)
    fun search(text: String) {
        document.location?.href = "${document.location?.origin}/search?searchText=${encodeURIComponent(text)}"
    }

    input?.addEventListener("keydown", { event ->
        val value = (event.target as? HTMLInputElement)?.value
        val code = (event as KeyboardEvent).keyCode
        if (code == 13 && !value.isNullOrBlank()) {
            search(value)
        }
    })

    element.querySelector("span")?.addEventListener("click", {
        input?.value?.takeIf { it.isNotBlank() }?.let {
            search(it)
        }
    })

    val searchText = document.location?.href?.let { URL(it) }?.searchParams?.get("searchText")
    if (searchText != null) {
        input?.value = searchText
    }

    input?.focus()
}
package com.steamstreet.vegasful.browser.public

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*

/**
 * Initialize all of the modules.
 */
private fun initializeModules() {
    document.querySelectorAll("[data-module]").asList().forEach {
        (it as? Element)?.let { element ->
            if (element.getAttribute("data-module-initialized") != "1") {
                element.getAttribute("data-module")?.let { module ->
                    modules[module]?.invoke(element)
                    element.setAttribute("data-module-initialized", "1")
                }
            }
        }
    }
}

/**
 * The mapping of modules.
 */
private val modules = mapOf(
    "global-search" to ::globalSearch,
    "calendar-selection" to ::calendarSelector,
    "event-scroller" to ::eventScroller,
    "ios-webapp-back" to ::iosWebAppBack
)

fun main() {
    (window.asDynamic())["initializeModules"] = ::initializeModules
    if (document.readyState === DocumentReadyState.COMPLETE || document.readyState === DocumentReadyState.INTERACTIVE) {
        initializeModules()
    } else {
        document.addEventListener("DOMContentLoaded", {
            initializeModules()
        })
    }
}
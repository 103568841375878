package com.steamstreet.vegasful.browser.public

import org.w3c.dom.Element
import org.w3c.dom.asList

fun eventScroller(element: Element) {
    val content = element.querySelector(".event-scroller-content")
    val contentElement = content?.firstElementChild
    element.querySelectorAll(".scroll-navigation-button").asList().forEach {
        (it as? Element)?.let {
            val direction = it.getAttribute("data-direction")
            it.addEventListener("click", {
                var widthToScroll = (contentElement?.clientWidth ?: 0) + 20
                if (direction == "left") {
                    widthToScroll = -widthToScroll
                }
                content?.parentElement?.scrollBy(widthToScroll.toDouble(), 0.0)
            })
        }
    }
}